import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/fede/source/largoplacismo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "quién-ha-creado-este-sitio",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#qui%C3%A9n-ha-creado-este-sitio",
        "aria-label": "quién ha creado este sitio permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`¿Quién ha creado este sitio?`}</h2>
    <p>{`Este sitio fue creado por `}<a parentName="p" {...{
        "href": "https://www.finmoorhouse.com"
      }}>{`Fin Moorhouse`}</a>{`, investigador del `}<a parentName="p" {...{
        "href": "https://www.fhi.ox.ac.uk"
      }}>{`Future of Humanity Institute`}</a>{` de la Universidad de Oxford.`}</p>
    <p>{`He recibido valiosos consejos y comentarios de Darius Meissner, Avital Balwit, Spencer Becker-Kahn, William MacAskill, Max Daniel y Stephen Clare. Los errores que pueda haber son míos.`}</p>
    <h2 {...{
      "id": "por-qué-existe-este-sitio",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#por-qu%C3%A9-existe-este-sitio",
        "aria-label": "por qué existe este sitio permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`¿Por qué existe este sitio?`}</h2>
    <p>{`Creo que el largoplacismo es una idea importante y fascinante, y espero que cada vez se aprecie más. Pero cuando busqué recursos introductorios en Internet, me sorprendió lo poco que existía ya. En concreto, el material existente suele presuponer que se está familiarizado con la filosofía o el altruismo eficaz, o bien habla mucho del largoplacismo sin introducirlo cuidadosamente. Así que decidí crear este sitio web para ponerme al día sobre el largoplacismo.`}</p>
    <p>{`Si tienes críticas, comentarios o preguntas, puedes ponerte en contacto conmigo por correo electrónico en `}<strong parentName="p">{`finlay `}{`[punto]`}{` moorhouse `}{`[arroba]`}{` philosophy `}{`[punto]`}{` ox `}{`[punto]`}{` ac `}{`[punto]`}{` uk`}</strong>{`. Me encantaría conocer tu opinión.`}</p>
    <p>{`La ilustración de la página `}<a parentName="p" {...{
        "href": "https://largoplacismo.com/introduccion/"
      }}>{`Introducción`}</a>{` es una imagen de dominio público `}<a parentName="p" {...{
        "href": "https://www.flickr.com/photos/britishlibrary/11199153996"
      }}>{`facilitada por la Biblioteca Británica`}</a>{`.`}</p>
    <p>{`El sitio ha sido traducido al español por `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/javierlopezcorpas/en/?originalSubdomain=es"
      }}>{`Javier López Corpas`}</a>{` y `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/cristinarodriguezdoblado/?locale=en_US"
      }}>{`Cristina Rodríguez Doblado`}</a>{`, como parte de un proyecto de traducción coordinado por `}<a parentName="p" {...{
        "href": "https://www.mod-langs.ox.ac.uk/people/laura-gonzalez-salmeron"
      }}>{`Laura González Salmerón`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      